import './app.css';
import Header from "./components/app-layers/header/header";
import LeftSidebar from "./components/app-layers/left-sidebar/left-sidebar";
import RenderRouters from './components/routers/router';

function App() {
  return (
    <div className="app">
      <LeftSidebar />
        <div className="right-side">
          <Header />
          <div className='aplications-window'>
            <RenderRouters />
          </div>
        </div>
    </div>
  );
}

export default App;

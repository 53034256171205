import { Route, Routes } from 'react-router-dom';
import AboutMe from '../app-layers/aplications/about-me';
import Devsite from '../dev-site/devsite';
import Webaplications from '../web-aplications/webaplications';
import Webnotise from '../web-aplications/web-notise/Webnotise';


function RenderRouters() {
  return (
		<Routes>
			<Route path="*" element={<AboutMe />} />
			<Route path="dev-sites" element={<Devsite />} />
			<Route path="web-aplications" element={ <Webaplications /> } />
			<Route path="online-advertisements" element={ <Devsite /> } />
			<Route path="telergam-app" element={ <AboutMe /> } />
			<Route path="after-effects-sample" element={ <AboutMe /> } />
			<Route path="web-aplications/web-notise" element={ <Webnotise /> } />
		</Routes>
  );
}

export default RenderRouters;
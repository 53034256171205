import { useEffect, useState } from 'react';

const RandomNumberCode = () => {
	type NumberArray = number[];

	let [randomCode, setRandomCode] = useState<NumberArray>([]);

	useEffect(() => {
		const intervalId = setInterval(() => {
		  setRandomCode(prevRandomCode => {
			// Проверка длины массива
			if (prevRandomCode.length > 15) {
			  return []; // Сброс массива, если длина больше 15
			}
	
			const minCeiled = Math.ceil(2);
			const maxFloored = Math.floor(1);
	
			// Генерация случайного числа
			const number =  Math.floor(Math.random() * (minCeiled - maxFloored) * minCeiled);
	
			// Возвращаем новый массив с добавлением нового числа
			return [...prevRandomCode, number];
		  });
		}, 1000);
	
		// Очистка интервала при размонтировании компонента
		return () => clearInterval(intervalId);
	  }, []); // Пустой массив зависимостей означает, что эффект выполнится только один раз при монтировании

	return (
		<p>{ randomCode }<span style={{ opacity: 1 }}>|</span></p>
	);
}

export default RandomNumberCode;
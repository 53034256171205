import { useEffect, useState } from 'react';

// 'https://api.coingecko.com/api/v3/news'
// 'http://dev-khlystikam.ru/json/'

const responseReload:any = fetch('http://dev-khlystikam.ru/php/pars-news/index.php');
if (!responseReload) {
  throw new Error('Network response was not ok');
}

const NewsJsonGet = () => {
  interface Article {
    data:any;
    title: string;
    url: string;
    imgurl: string;
    description: string;
    postDate: string;
    author: string;
    link: string;
	}


  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
	let [item, setItem] = useState<number>(0);
	const [activeClass, setActiveClass] = useState('class1');

  useEffect(() => {
    const fetchData = async () => {
      let data:any;

        try {
        const response = await fetch('http://dev-khlystikam.ru/php/pars-news/news.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        if (response) {
          data = await response.json();
        }

        setArticles(data);
        setLoading(false);

      } catch (error) {
        setError((error as Error).message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveClass('news-link-close');
      const timeoutId = setTimeout(() => {
        setActiveClass('news-link-open');
      }, 1000);

		setTimeout(()=> setItem(timeInterval()),1000);


		// Очищаем таймер на каждом цикле
		return () => clearTimeout(timeoutId);
  	}, 5000);


	  // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  });


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

	function timeInterval(){
		if(item === 10){
			item = 0;
			return item;
		} else {
			item += 1;
			return item;
		}
	}


  return (
    <span>
			<a className={ activeClass } href={ articles[item].data.link } target="_blank" rel="noopener noreferrer">
				<div className="news-detals-name-img">
					<div className="news-detals-img">
						<img className="img-news" src={articles[item].data.imgurl} alt={articles[item].data.title} />
					</div>
					<p className="name-news">«{ (articles[item].data.title) + "..."}»</p>
				</div>
				<div className="news-detals">
					<p className="date-news">{ articles[item].data.postDate }</p>
					<p className="author-news"> / { articles[item].data.author }</p>
				</div>
			</a>
    </span>
  );
};

export default NewsJsonGet;
import { NavLink } from 'react-router-dom';
import './webaplications.css';

function Webaplications() {
	interface Item {
		index:any;
    	name: string;
		path: string;
		pathImgSite: string;
	}

	const listSites: Item[] = [
		{index: 0, name:'Web объявления', path:"web-notise", pathImgSite:"./assets/web-aplications/OIG4.jpg"},
		{index: 1, name:'webaplications 2', path:"./webaplications_2", pathImgSite:"./assets/web-aplications/OIG4.jpg"},
		{index: 2, name:'webaplications 3', path:"./webaplications_3", pathImgSite:"./assets/web-aplications/OIG4.jpg"},
		{index: 3, name:'webaplications 4', path:"./webaplications_4", pathImgSite:"./assets/web-aplications/OIG4.jpg"},
        {index: 4, name:'webaplications 5', path:"./webaplications_5", pathImgSite:"./assets/web-aplications/OIG4.jpg"},
	];

    const itemsSiteList:any = listSites.map(element => {

        return (
            <NavLink 
                to={ element.path }
                className="web-aplications-block"
                id={ element.index }
                key={ element.index }
                >

                <div className="web-aplications-img-box">
                    <img className="web-aplications-img" src={ element.pathImgSite } alt={ element.name } />
                </div>
                <p className="web-aplications-name-text">{ element.name }</p>
            </NavLink>
        )
    });

    return (
        <div className="web-aplications-box">
            <div className="web-aplications-box-description">
                <p>На этой странице отображены мои web-приложения для решения разных небольших задач. Реализация с помощью технологий: REACT + TYPESCRIPT, PHP, SQL.</p>
                <div className="img-web-aplications-box">
                    <img src="./assets/web-aplications/OIG4.jpg" alt="img" />
                </div>
            </div>
            <div className="web-aplications-box-items">
                { itemsSiteList }
            </div>
        </div>
    )
}
  
export default Webaplications;
import { useEffect, useRef, useState } from 'react';
import './fadeinsection-left.css';

const FadeInSectionLeft = (props:any) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef:any = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // observer.unobserve(domRef.current);
        }
      });
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <span
      className={`fade-in-left ${isVisible ? 'show' : ''}`}
      ref={domRef}
    >
      {props.children}
    </span>
  );
}

export default FadeInSectionLeft;
import './header.css';
import NewsJsonGet from './news-get-json';


const Header = () => {
	return (
		<header className='header'>
			<div className="news-web-dev">
				<NewsJsonGet />
			</div>
			<div className="second-block"></div>
			<div className="third-block"></div>
		</header>
	);
};

export default Header;
import './WebNotiseApp.css';

function WebNotiseApp() {
    interface definedNotisItems {
        index:number;
        nameNotise: string;
        textNotise: string;
        tagNotise: string;
        ImgNotise: string;
    }

    const notisItems: definedNotisItems[] = [
        {index: 0, nameNotise:'Test 1', textNotise:"text notise 1", tagNotise:"tag notise 1", ImgNotise:`${process.env.PUBLIC_URL}./assets/web-aplications/OIG4.jpg`},
        {index: 1, nameNotise:'Test 2', textNotise:"text notise 2", tagNotise:"tag notise 2", ImgNotise:`${process.env.PUBLIC_URL}./assets/web-aplications/OIG4.jpg`},
        {index: 2, nameNotise:'Test 3', textNotise:"text notise 3", tagNotise:"tag notise 3", ImgNotise:`${process.env.PUBLIC_URL}./assets/web-aplications/OIG4.jpg`},
        {index: 3, nameNotise:'Test 4', textNotise:"text notise 4", tagNotise:"tag notise 4", ImgNotise:`${process.env.PUBLIC_URL}./assets/web-aplications/OIG4.jpg`},
        {index: 4, nameNotise:'Test 5', textNotise:"text notise 5", tagNotise:"tag notise 5", ImgNotise:`${process.env.PUBLIC_URL}./assets/web-aplications/OIG4.jpg`},
    ];

    const itemsNotise:any = notisItems.map(element => {
        return (
            <div className="web-notise-app_block">
                <div className="web-notise-text-box">
                    <h1 className='web-notise-text-box_h1'>{ element.nameNotise }</h1>
                    <p className='web-notise-text-box_text'>{ element.textNotise }</p>
                    <span className='web-notise-text-box_line'></span>
                    <p className='web-notise-text-box_tag'>{ element.tagNotise }</p>
                </div>
                <div className="web-notise-img-box">
                    <img className="web-notise-img-box_img" src={ element.ImgNotise } alt={ element.nameNotise } />
                </div>
            </div>
        )
    });


    return (
        <div className="web-notise-white-background">
            { itemsNotise }
        </div>
    )
}
  
export default WebNotiseApp;
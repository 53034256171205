import './webnotise.css';
import WebNotiseApp from './components/WebNotiseApp/WebNotiseApp';

function Webnotise() {
    return (
        <div className="web-notise-box">
            <h1>web объявления</h1>
            <div className="web-notise-app">
                < WebNotiseApp />
            </div>
            <div className="web-notise-admin">
                web-notise-admin
            </div>
        </div>
    )
}
  
export default Webnotise;